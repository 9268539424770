<template>
  <v-select
    v-model="gender"
    v-bind="$attrs"
    :items="[
      { text: $options.filters.capitalize($t('male')), value: 'male' },
      { text: $options.filters.capitalize($t('female')), value: 'female' },
      { text: $options.filters.capitalize($t('unknown')), value: 'unknown' }
    ]"
    menu-props="offsetY"
    @change="$emit('update:value', gender)"
  />
</template>
<script>
  export default {
    props: {
      value: {
        type: [Array, String],
        default: () => [],
      },
    },
    data () {
      return {
        gender: this.value,
      }
    },
    watch: {
      value () {
        this.gender = this.value
      },
    },
  }
</script>
